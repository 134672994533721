import { createManualInvoice } from '@api/Invoices'
import { Alert, CheckBox, Divider, FormButton, FormInput, FormMoneyInput, FormUndefinedInitialValue } from '@elements'
import { formatMoney } from '@helpers/display'
import { MoneyCalc, isMoney, makeMoney } from '@helpers/money'
import { Farm } from '@models/Farm'
import { MoneyWithCurrency } from '@models/Money'
import { Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import * as Yup from 'yup'

import { adminCurrencySelector } from '@/redux/selectors'
import { YUP_MONEY_REQUIRED } from '@helpers/Yup'
import { useSelector } from 'react-redux'
import { Logger } from '../../../../../config/logger'
import { globalStyles } from '../../../../../constants/Styles'

type FormType = {
  amount: MoneyWithCurrency
  message: string
  markAsPaid: boolean
  note: string
}

const initialValues: FormType = {
  amount: FormUndefinedInitialValue,
  message: '',
  markAsPaid: false,
  note: '', // this is the note that will be added to the invoice
}

type Props = {
  userId: string
  farm: Pick<Farm, 'name' | 'id'>
  uniqueId?: string
  onUpdate?: () => void
}

const validationSchema = Yup.object<FormType>().shape({
  amount: YUP_MONEY_REQUIRED('Amount', { requireCurrency: true }).test(
    'gt-1',
    `The amount cannot be less than $1.00`,
    (val) => isMoney(val) && MoneyCalc.isGTE(val, makeMoney(100)),
  ),
  message: Yup.string().trim().min(5, 'Please enter a message at least 5 characters').label('Message').required(),
})

/** Modal to create a manual invoice */
export function CreateInvoice({ userId, farm }: Props) {
  const [loading, setLoading] = useState(false)

  const onSubmitHandler = async ({ amount, message, markAsPaid, note }: FormType) => {
    setLoading(true)
    try {
      await createManualInvoice({
        userId,
        farmId: farm.id,
        amount,
        description: message,
        markAsPaid,
        note,
      })
      setLoading(false)
      Alert('Invoice Successfully Created', `You have successfully created an invoice for ${formatMoney(amount)}.`)
    } catch (err) {
      Logger.error(err)
      setLoading(false)
      Alert(
        'Invoice Creation Failed',
        'Failed to create invoice, please try again later or contact support if this issue persists.',
      )
    }
  }
  const currency = useSelector(adminCurrencySelector)

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={validationSchema}>
      {({ values, errors, setFieldValue, handleSubmit, handleBlur, touched }: FormikProps<FormType>) => (
        <View style={globalStyles.margin10}>
          <FormMoneyInput
            value={values.amount}
            label="Amount to invoice the customer"
            onBlur={handleBlur('amount')}
            onChangeText={(val) => setFieldValue('amount', val)}
            errorMessage={
              touched.amount && !!errors.amount
                ? typeof errors.amount === 'string'
                  ? errors.amount
                  : 'Invalid input'
                : ''
            }
            currency={currency}
          />
          <FormInput
            multiline
            numberOfLines={2}
            placeholder="Message to customer"
            errorMessage={touched.message ? errors.message : ''}
            value={values.message}
            onChangeText={(val) => setFieldValue('message', val)}
            onBlur={handleBlur('message')}
          />
          <CheckBox
            style={styles.checkboxMargin}
            checked={values.markAsPaid}
            onChange={(val) => setFieldValue('markAsPaid', val)}
            title="Mark invoice as paid offline"
          />
          <Divider clear />
          {values.markAsPaid && (
            <FormInput
              multiline
              numberOfLines={2}
              placeholder="Make a note for this invoice (optional)"
              value={values.note}
              onChangeText={(val) => setFieldValue('note', val)}
            />
          )}

          <FormButton loading={loading} title="Create Invoice" onPress={handleSubmit} />
          <Text style={globalStyles.margin10}>
            This invoice and message will be emailed to the customer. If marked "paid offline", the customer will
            receive a receipt.
          </Text>
        </View>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  checkboxMargin: {
    marginLeft: 10,
  },
})
